import React from "react";
import { translate } from "react-i18next"
import {connect} from 'react-redux';
import Swiper from 'react-id-swiper';
import "../css/app.scss";
import { millisToMinutesAndSeconds, plural } from '../../utils'
import LongPress from "./longPress"
import { withMixpanel } from 'gatsby-plugin-mixpanel'
import {persistor} from "../../state/ReduxWrapper";


class EndGame extends React.Component {

  state = {
    title : "title fadeInUpSmall animated slow delay-1s ",
    card : "card main fadeIn animated",
    lineLeft: "line fadeInLeft animated delay-1s ",
    lineRight: "line fadeInRight animated delay-1s ",
    success: "success-title fadeIn animated delay-2s slow",
    pub: "pub fadeIn animated delay-3s slow hidden",
    inputmailValue:"",
    sent:false
  }

  sliderParams = {
    speed: 700,
    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
    },

  }

  goNext() {
    console.log("next")
    if (this.swiper !== null) {
      this.swiper.slideNext();
    }
  };

  goPrev() {
    console.log("previous")
    if (this.swiper !== null) {
      this.swiper.slidePrev();
    }
  };

  start() {
    this.setState({
      title : "fadeOut animated delay-1s",
      difficulty : "fadeOut animated delay-1s",
      btn : "fadeOut btn animated clicked",
      logo:"fadeOut animated delay-1s"
    })
    setTimeout(() => {
      this.props.onClose();
    }, 2000);
    
  }  

  getIndiceUsed() {
    const {items} = this.props;

    let nb = 0;
    items.map((element) => {
      nb += element.cluesUsed
    })

    return nb;
  }

  getScoreValue() {
    const time = Math.floor((this.props.end - this.props.start) / 60000);
    const cluses = this.getIndiceUsed();
    
    let score = 100;
    if(time >= 26 && time < 30)
      score -= 10;
    else if (time >= 30 && time < 40)
      score -= 30;
    else if (time > 40)
      score -= 50;
    
    score -= (5*cluses);

    if(score === 100)
      score = 5;
    else if (score < 100 && score >= 80)
      score = 4;
    else if (score < 80 && score >= 60)
      score = 3;
    else if (score < 60 && score > 40)
      score = 2;
    else if (score < 40)
      score = 1;

    return score;
  }

  getScore() {
    const score = this.getScoreValue()

    let visual = []
    for(let i = 0 ; i < score ; i++) {
      visual.push(
        <img src={require('../img/shield_on.png')} key={"star"+i}/>
      )  
    }
    
    for(let i = 0 ; i < 5-score ; i++) {
      visual.push(
        <img src={require('../img/shield_off.png')} key={"no-star-"+i}/>
      )  
    }

    return visual;
  }

  shareScore() {

    const { t } = this.props

    const title = t("share_title");
    const text =  t("share_content").replace("%%SCORE%%", millisToMinutesAndSeconds(this.props.end - this.props.start));
    const url = 'https://jeu.rhonexpress.fr/';

    console.log(text);

    if (!navigator.share){
      return;
    }

    navigator.share({
      title,
      text,
      url
    }); // partage l'URL de MDN
  }

  handleChangeInput(e) {
    this.setState ({
      inputmailValue:e.target.value
    })
  }

  sendMail() {
    const { i18n, mixpanel } = this.props
    const ln = (i18n.language)?i18n.language:"en";

    if(this.state.inputmailValue) {
      let url = "https://butterfly-entertainment.com/magnitude/atlantide/rxp/mail.php?"
      url += "email="+this.state.inputmailValue+"&";
      url += "time="+(this.props.end - this.props.start)+"&";
      url += "score="+this.getScoreValue()+"&";
      url += "ln="+ln;

      fetch(url);

      this.setState({
        sent:true
      })
    }

    mixpanel.track("mail_sent");
  }

  render() {
    const { t } = this.props
    const cluses = this.getIndiceUsed();

    return <div id={"endgame-container"}>

      <Swiper {...this.sliderParams} getSwiper={(swiper) => { this.swiper = swiper }}>
        <div>
          <div className={this.state.card}>
            <span className={this.state.title}>
              {t("title")}
            </span>

            <div className={"success-container"}>
              <span className={this.state.lineLeft}></span>
              <span className={this.state.success}>
                {t("success")}
              </span>
              <span className={this.state.lineRight}></span>
            </div>

            <div className="round fadeIn animated delay-3s" onClick={() => { this.goNext()}}>
              <div id="cta">
                  <span className="arrow primera next "></span>
                  <span className="arrow segunda next "></span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={"card white"}>
            <div className={"game-title"}>
              {t("score-title")}
            </div>

            <div className={"stars"}>
              {this.getScore()}
            </div>


            <div className={"game-time"}>
              <img src={require('../img/time.svg')}/>
              <div>
                <span className="score">{ millisToMinutesAndSeconds(this.props.end - this.props.start) } </span>
                <span className="legend">{t("legend-time")}</span>
                </div>
            </div>

            <div className={"game-time"}>
              <img src={require('../img/icon_bulb.png')}/>
              <div>
                <span className="score">{cluses} {t("indice")}{plural(cluses)} {t("utilise"+plural(cluses))}</span>
                <span className="legend">{t("legend-cluses")}</span>
                </div>
            </div>

            {(navigator.share)?
            <div className={"share"}>
              <img src={require('../img/share.svg')} onClick={() => {this.shareScore()}}/>
              <div className="btn hidden" onClick={() => {this.shareScore()}}>{t("share-score")}</div>
            </div>:null}

            <div className="block-centered hidden">
              <div className="round fadeIn animated delay-3s" onClick={() => { this.goNext()}}>
                <div id="cta">
                    <span className="arrow primera next "></span>
                    <span className="arrow segunda next "></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className={"card white"}>
            <img src={require("../img/lyon.svg")} id="diploma"/>
            <div className='mail-container'>
              <p>
                {t("email-pitch")}
              </p>
              <div className="form">
                <input type="mail" value={this.state.inputmailValue} placeholder="E-MAIL" onChange={(e) => { this.handleChangeInput(e) }}/>
                {
                  (!this.state.sent)?
                    <div className={(!this.state.inputmailValue)?"btn inactive":"btn"} onClick={() => {this.sendMail()}}>
                      {t("send")}
                    </div>:
                    <span className="sent-message">{t("sent")}</span>
                }
              </div>
              
              
            </div>

            <div className="block-centered hidden">
              <div className="round fadeIn animated delay-3s" onClick={() => { this.goNext()}}>
                <div id="cta">
                    <span className="arrow primera next "></span>
                    <span className="arrow segunda next "></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={"card white promo"}>
            <a href='http://onelink.to/sgk4qe' targ="_blank"><img src={require("../img/pub.jpg")} id="promo"/></a>
            <a href='http://onelink.to/sgk4qe' targ="_blank"><div className={"btn"} >{t("discover")}</div></a>
          </div>
        </div>
      </Swiper>
        

      <LongPress
        time={700}
        onPress={() => persistor.purge() }
        onLongPress={() => persistor.purge() }>
          <img id="logo" src={require('../img/logo.png')} className={""} />
      </LongPress>
      <div className={this.state.pub}>
        <p>
          {t("more")}
        </p>
        <a href='https://apps.apple.com/fr/app/atlantide-io/id1364973121' target="_blank"><img src={require("../img/app_store.png")} /></a>
        <a href='https://play.google.com/store/apps/details?id=com.magnitudelabs.atlantide' target="_blank"><img src={require("../img/play_store.png")} /></a>
      </div>


    </div>;
  }
}

export default translate("endgame")(withMixpanel()(connect(state => ({ 
    start : state.system.startTime,
    end : state.system.endTime,
    items: state.inventory.items
  }),
  null
)(EndGame)));