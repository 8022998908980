import React from "react";
import {connect} from 'react-redux';
import { Helmet } from "react-helmet"
import { navigate } from 'gatsby';
import Particles from 'react-particles-js';
import { withNamespaces } from "react-i18next";

import Lucas from "../assets/components/lucas";
import LyonMap from "../assets/components/lyonmap";
import Inventory from "../assets/components/inventory";
import InventoryDetails from '../assets/components/inventoryDetails';
import Annecdote from '../assets/components/annecdote';
import NavBar from "../assets/components/navBar";
import Welcome from "../assets/components/welcome";
import EndGame from "../assets/components/endgame";
import PopupLucas from '../assets/components/popupLucas';
import {activeTab, closeItem, closeAnnecdote } from '../state/actions/system';
import {loadDiscussion} from "../state/actions/lucas"
import {closeWelcome} from "../state/actions/fl"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import particleConfig from '../assets/particules.json'
import i18n from '../i18n'

export const DEBUG = true;


class Index extends React.Component {

  state = {
    showPopupFL : true,
  }

  renderContent() {
    const { i18n } = this.props

    let class1 = "hidden";
    let class2 = "hidden";
    let class3 = "hidden";

    switch (this.props.activeTab) {
      case 1:  // MAP
        class1 = "";
        break;
      
      case 2: // INVENTAIRE
        class2 = "";
        break;

      case 3: // CHAT
        class3 = "";
        break;
    }

    let item = null;
    let id = null;
    if(this.props.item) {
      console.log(this.props.item);
      this.props.itemsBD.map((element, key) => {
        if(element.content[i18n.language].img === this.props.item) {
          item = {...element};
          id=key;
        }
      })
    }


    return (
      <div>
        <div className={class1}>
          <LyonMap />
        </div>
        <div className={class2}>
          <Inventory />
        </div>
        <div className={class3}>
          <Lucas />
        </div>

        {(item)?<InventoryDetails item={item} id={id} onExit={() => {this.closeItem()}} />:null}
        {(this.props.annecdote)?<Annecdote content={this.props.annecdote} onExit={() => {this.closeAnnecdote()}} />:null}
      </div>
    )
  }

  closeItem() {
    this.props.dispatch(closeItem());
  }

  closeAnnecdote() {
    this.props.dispatch(closeAnnecdote());
  }

  closePopUp() {
    this.props.dispatch(activeTab(3));
    setTimeout(() => {this.props.dispatch(loadDiscussion("fl"))},300);
    
    
    this.setState({
      showPopupFL: false
    })
  }

  componentDidMount() {
    window.addEventListener('load', function() {
      window.history.pushState({ noBackExitsApp: true }, '');
    })
    
    window.addEventListener('popstate', function(event) {
      if (event.state && event.state.noBackExitsApp) {
        window.history.pushState({ noBackExitsApp: true }, '');
      }
    })
  }

  getHelmet() {
    const seo = {
      title: "MISSION – RECONNEXION",
      description: "Sauvez la ville de Lyon dans un jeu unique à vivre dans le RhonExpress",
      url: "https://jeu.rhonexpress.fr/", // No trailing slash allowed!
      image: "/share.jpg", // Path to your image you placed in the 'static' folder
    }

    return  (
      <Helmet>
        <meta charSet="utf-8" />
        <title>{seo.title}</title>-
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <meta name="HandheldFriendly" content="true" />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <meta property="og:url" content={seo.url} />
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:image" content={seo.image} />
        <meta name="twitter:title" content={seo.title} />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:image" content={seo.image} />
      </Helmet>
    )
  }

  render() {
    let content;

    if(this.props.fl.showWelcome)
      content = <Welcome onClose={() => {this.props.dispatch(closeWelcome())}}/>

    else if(!this.props.fl.done && this.state.showPopupFL)
      content = (<PopupLucas onClose={() => {this.closePopUp()}}/>)
    
    else if(this.props.fl.showEndgame)
        content = <EndGame onClose={() => {}}/>

     else content = (
      <div>
        { this.renderContent() }
        <NavBar />
      </div>
    )

    return (
      <div>
        {this.getHelmet()}

        <img src={require("../assets/img/cover.jpg")} className="cover fadeIn animated"/>

        <div id='layer'></div>

        <Particles className="particles" params={particleConfig}/>

        <div id="nandsl">
          ONLY ON MOBILE
        </div>
        
        { content }
      </div>
    )
  }
}

export default withNamespaces()(connect(state => ({ 
        fl : state.fl,
        activeTab: state.system.activeTab,
        item: state.system.item,
        annecdote: state.system.annecdote,
        itemsBD:state.inventory.items,
    }),
    null
)(Index));
