import React from "react";
import {connect} from 'react-redux';
import "../css/app.scss";
import {activeTab, newOnMap, newOnFiles, newOnChat} from '../../state/actions/system';
import { animateScroll as scroll } from "react-scroll";
import { translate } from "react-i18next"

class NavBar extends React.Component {

    isActiveClass(id) {
        return (this.props.activeTab === id)?"active":"";
    }
    
    activate(id) {
        this.props.dispatch(activeTab(id));

        switch(id) {
            case 1:
                this.props.dispatch(newOnMap(false));
                break;
            case 2:
                this.props.dispatch(newOnFiles(false));
                break;
            case 3 :
                this.props.dispatch(newOnChat(false));
                setTimeout(() => {
                    scroll.scrollToBottom();
                },300);
                break;
        }
    }

    render() {
        const { t } = this.props
        const visible = (this.props.flDone)?"":"hidden-bar";

        return <div id="navbar" className={visible}>
            <div className={(!this.props.mapActive)?"tab disabled "+this.isActiveClass(1):"tab "+this.isActiveClass(1)} onClick={() => { if(this.props.mapActive) {this.activate(1)}}}>
                <img src={require("../img/ic_map@4x.png")} />
                <span>{t("map")}</span>
                {(this.props.newOnMap)?<span className="puce"></span>:null}
            </div>
            <span className={"vert-line"}></span>
            <div className={"tab "+this.isActiveClass(2)}  onClick={() => { this.activate(2)}}>
                <img src={require("../img/ic_folder@4x.png")} />
                <span>{t("inventory")}</span>
                {(this.props.newOnFiles)?<span className="puce"></span>:null}
            </div>
            <span className={"vert-line"}></span>
            <div className={"tab "+this.isActiveClass(3)}  onClick={() => { this.activate(3)}}>
                <img src={require("../img/ic_mail@4x.png")} />
                <span>{t("chat")}</span>
                {(this.props.newOnChat)?<span className="puce"></span>:null}
            </div>

        </div>;
    }
}

export default translate("navbar")(connect(state => ({ 
    flDone : state.fl.done,
    activeTab : state.system.activeTab,
    newOnMap : state.system.newOnMap,
    newOnFiles : state.system.newOnFiles,
    newOnChat : state.system.newOnChat,
    mapActive:state.map.active,
}),
null
)(NavBar));