import React from "react";
import PinchZoomPan from "./PinchZoomPan/PinchZoomPan";
import {connect} from 'react-redux';
import {openItem, getClue} from "../../state/actions/inventory"
import "../css/app.scss";
import Image from './Image';
import { translate } from "react-i18next"

class InventoryDetails extends React.Component {

  state = {
    showing : true,
    showClues:false
  }

  componentDidMount() {
    console.log(this.props.id);
/*
    if(this.props.id !== null && this.props.id !== undefined)
      this.props.dispatch(openItem(this.props.id))
*/
    setTimeout(() => {
      this.setState({
        showing:false
      })
    }, 100)

    console.log("Details element ");
    console.log(this.props.item);
  }
  
  toogleClues() {
    this.setState({showClues:!this.state.showClues})
  }

  getCluesContainer() {
    const containerClass = (this.state.showClues)?"indices-content show":"indices-content";
    return (
      <div className={containerClass} >
        <div id='clues-container'>
          {this.getClues()}
          <img src={require('../img/close_aqua.svg')} className="back-top-right" onClick={() => { this.toogleClues() }}/>
        </div>
        <div id='clues-bg' onClick={() => { this.toogleClues() }}></div>
      </div>
    );
  }
  
  getClues() {
    const { i18n } = this.props
    const cluesUsed = this.props.items[this.props.id].cluesUsed
    const lastOne = cluesUsed+1 == this.props.item.content[i18n.language].clues.length
    const hasMore = cluesUsed < this.props.item.content[i18n.language].clues.length
    const first = cluesUsed == 0;
    let cluesContent;

    if(first) {
      cluesContent = (
        <p>
          Il n'y a pas de pénalité à demander un indice.
        </p>
      )
    } else {
      const cluesList = []
      for (let i = 0 ; i < cluesUsed ; i++)
        cluesList.push(<li key={"indice"+i}>{this.props.item.content[i18n.language].clues[i]}</li>)

      cluesContent = (
        <ul>
        { cluesList }
        </ul>
      )
    }

    return (
      <div id="parent">
        {cluesContent}
        {(hasMore)?<div className="btn" onClick={() => { this.nextClue() }}>{(lastOne)?<span>Réponse</span>:<span>Obtenir un indice</span>}</div>:null}
      </div>
    )
  }

  nextClue() {
    console.log("next");
    const { i18n } = this.props;
    this.props.dispatch(getClue(this.props.id, i18n.language));
  }

  render() {
    const params = {
      position:"center",
      zoomButtons:false,
      doubleTapBehavior:"zoom"
    }

    const { i18n, t } = this.props

    const showingClass = (this.state.showing)?"showing":"";
    const showingClassIndice = (this.state.showClues)?"indices show":"indices";

    
    return <div id="item-details-container" className={showingClass}>
      <div id='background-catcher' onClick={() => { this.props.onExit() }} ></div>
      <div className={showingClassIndice} onClick={() => { this.toogleClues() }}>
        <div className="icon-container">
          <img src={require('../img/icon_bulb_white.png')} id='white'/>
          <img src={require('../img/icon_bulb.png')} id='blue'/>
        </div>
        {t("clues")}
      </div>
      {this.getCluesContainer()}

      <img src={require('../img/close_white.svg')} className="back-top-right" onClick={() => { this.props.onExit() }}/>

      <PinchZoomPan {...params}>
        <img src={require("../img/items/"+this.props.item.content[i18n.language].img)} />
        {/*<Image alt="" filename={this.props.item.content[i18n.language].img} />*/}
      </PinchZoomPan>
    </div>;
  }
}

export default translate()(connect(state => ({ 
        activeTab: state.system.activeTab,
        items: state.inventory.items
    }),
    null
)(InventoryDetails));