import React from "react";
import {connect} from 'react-redux';
import { translate } from "react-i18next"

import {done, tooglePlay, endGame} from '../../state/actions/fl';
import {addMessage} from '../../state/actions/lucas';
import {newOnChat, newOnFiles, openAnnecdote, end} from '../../state/actions/system';
import {showPOI, toogleMap} from '../../state/actions/map';
import {loadDiscussion} from "../../state/actions/lucas"
import {addItem} from "../../state/actions/inventory"
import Chat from './chat';
import UserMessage from "./userMessage";
import { withMixpanel } from 'gatsby-plugin-mixpanel'

import { isDev } from '../../utils'
import "../css/app.scss";
import { animateScroll as scroll } from "react-scroll";

import textFL from 'raw-loader!../lucas/fr/firstloop.txt';
import textOR from 'raw-loader!../lucas/fr/or.txt';
import textBasilique from 'raw-loader!../lucas/fr/basilique.txt';
import textCanut from 'raw-loader!../lucas/fr/canut.txt';
import textBellecourt from 'raw-loader!../lucas/fr/bellecourt.txt';
import textHotel from 'raw-loader!../lucas/fr/hotel.txt';
import textFLEN from 'raw-loader!../lucas/en/firstloop.txt';
import textOREN from 'raw-loader!../lucas/en/or.txt';
import textBasiliqueEN from 'raw-loader!../lucas/en/basilique.txt';
import textCanutEN from 'raw-loader!../lucas/en/canut.txt';
import textBellecourtEN from 'raw-loader!../lucas/en/bellecourt.txt';
import textHotelEN from 'raw-loader!../lucas/en/hotel.txt';

import {persistor} from "../../state/ReduxWrapper";

const lucasMessageSpeed = 1;

class Lucas extends React.Component {

  state = {
    lucasMessagesList : [],
    lucasMessageId : 0,
    playing:false,
    userAnswer : {
      content:null,
      format:'',
      showing:false,
    },
    typing: false,
    ln:"en"
  }

  txtsBD = {
    "fr": {
      "fl":textFL,
      "or":textOR,
      "basilique":textBasilique,
      "canut":textCanut,
      "bellecourt":textBellecourt,
      "hotel":textHotel
    },
    "en": {
      "fl":textFLEN,
      "or":textOREN,
      "basilique":textBasiliqueEN,
      "canut":textCanutEN,
      "bellecourt":textBellecourtEN,
      "hotel":textHotelEN
    }
  }

  addMessage(nextMessage, isUserAnswer) {
    if(this.props.activeTab != 3) {
      this.props.dispatch(newOnChat(true));
    }

    return addMessage(nextMessage, isUserAnswer)
  }

  loadText() {
    console.log("loading text")
    const { i18n } = this.props
    const ln = (i18n.language)?i18n.language:"en";
    let txt = this.txtsBD[ln][this.props.discussion];

    const txts = this.parseLucasText(txt);

    this.setState({
      lucasMessagesList:txts,
      lucasMessageId:0,
      ln
    });

    this.props.dispatch(tooglePlay(true))
    persistor.pause();
  }

  parseLucasText(text) {
    const splittedText = text.split("\n\n"); 
    return splittedText;
  }

  componentDidMount() {
    const { i18n } = this.props

    const ln = (i18n.language)?"en":i18n.language;
    this.setState({
      ln: ln
    });

    /*if(this.props.discussion) {
      console.log("had texte "+this.props.discussion)
      this.loadText();
      // this.props.dispatch(loadDiscussion(null));
    }*/

    this.scrollToBottom();
    
    this.props.dispatch(loadDiscussion(null));
    this.props.dispatch(tooglePlay(false))
  }

  componentDidUpdate(prevProps, prevState) {
    if(! prevProps.discussion && this.props.discussion) {
      this.loadText();
      this.props.dispatch(loadDiscussion(null));
      this.scrollToBottom("instant");
    } else {
      if (this.props.play !== prevProps.play && this.props.play) {
        this.nextLucasMessage();
      } 
    }

    this.scrollToBottom();
  } 
  
  nextLucasMessage() {
    if(this.props.play) {
      const hasNext = this.state.lucasMessageId < this.state.lucasMessagesList.length;

      let nextMessage = "";
      let isUserAnswer = false;

      if(hasNext) {
        nextMessage = this.state.lucasMessagesList[this.state.lucasMessageId];
        const playerAnswer = nextMessage.split('J:');
        let gotoNext = true;

        const isAnnecdote = nextMessage.includes("%%ANNECDOTE_");       
        const isUnlock = nextMessage.includes("%%UNLOCK_");       
        const isEndGame = nextMessage.includes("%%ENDGAME");
        const isSendEvent = nextMessage.includes("%%EVENT_");       
        
        isUserAnswer = playerAnswer.length > 1;

        if(isEndGame) {
            console.log("fin du jeu");
            persistor.persist();
            this.props.dispatch(end());
            this.props.dispatch(endGame());
        } else if (isSendEvent){
          let message = nextMessage.split("%%EVENT_")[1];
          
          const { mixpanel } = this.props
          mixpanel.track((isDev()?"dev_":"")+message.toLowerCase());

          console.log(message.toLowerCase());

          this.setState({
            lucasMessageId: this.state.lucasMessageId+1,
            typing:false,
          })
  
          this.nextLucasMessage();

        } else if(isUserAnswer) {
          console.log("user answer");
          nextMessage = playerAnswer[1];

          gotoNext = false;

          this.setState({
            userAnswer:{
              ...this.state.userAnswer,
              content:nextMessage,
              showing:true
            },
            typing:false,
          });

          setTimeout(() => {
            this.setState({
              userAnswer:{
                ...this.state.userAnswer,
              }
            });
          }, 700);

          this.props.dispatch(tooglePlay(gotoNext));

        } else if(isAnnecdote) {
          const annecdoteID = nextMessage.split("%%ANNECDOTE_")[1];

          setTimeout(() => {
            this.props.dispatch(openAnnecdote(annecdoteID));
            this.props.dispatch(tooglePlay(true));
          }, 300);

          setTimeout(() => {
            this.setState({
              lucasMessageId: this.state.lucasMessageId+1,
              typing:false,
            })
    
            this.nextLucasMessage();
          }, 500);
        } else if(isUnlock) {
          const unclockName = nextMessage.split("%%UNLOCK_")[1];

          this.props.dispatch(showPOI(unclockName));
          this.props.dispatch(toogleMap(true));
          this.props.dispatch(tooglePlay(true));

          this.setState({
            lucasMessageId: this.state.lucasMessageId+1,
            typing:false,
          })
  
          this.nextLucasMessage();
        } else {
          console.log("lucas answer");

          if(nextMessage === "%%END") {
            this.props.dispatch(tooglePlay(false));
            persistor.persist();
            return;
          } else if(nextMessage === "%%ENDFIRSTLOOP%%") {
            this.props.dispatch(tooglePlay(false));
            this.props.dispatch(done());
            persistor.persist();
            setTimeout(() => {
              //scroll.scrollToBottom({containerId:"chat-container"});
            },1000);
            return;
          } else if(nextMessage.includes("%%ADD")) {
            const fileID = nextMessage.split("%%ADD_")[1].split("%")[0];
            
            this.props.dispatch(addItem(fileID));
            this.props.dispatch(newOnFiles(true));
          } 

          if(this.state.lucasMessageId !== 0 && hasNext) {
            setTimeout(() => {
              this.setState({
                typing:true,
              })
            }, 500);
          }

          const timer = setTimeout(() => {
            if (nextMessage.includes("%%RIDDLES_LEFT"))
              nextMessage = nextMessage.replace("%%RIDDLES_LEFT", this.getRiddlesLeft()-1)
            
            nextMessage = nextMessage.replace(":)", "😀");            
            nextMessage = nextMessage.replace(";)", "😉");            
            nextMessage = nextMessage.replace(";p", "😜");            
            nextMessage = nextMessage.replace(":D", "😀");            
            nextMessage = nextMessage.replace(":/", "😕");            
            nextMessage = nextMessage.replace(":|", "😐");            
            nextMessage = nextMessage.replace("<3", "💖");            
            nextMessage = nextMessage.replace(":bravo:", "👏");
            nextMessage = nextMessage.replace(":geek:", "🤓");
            nextMessage = nextMessage.replace(":+1:", "👍");
            nextMessage = nextMessage.replace(":fist:", "👊");
            nextMessage = nextMessage.replace(":hi:", "👋");
            nextMessage = nextMessage.replace(":hi5:", "🙌");
            nextMessage = nextMessage.replace(":eyes:", "👀");

            this.props.dispatch(this.addMessage(nextMessage, isUserAnswer));
            this.setState({
              lucasMessageId: this.state.lucasMessageId+1,
              typing:false,
            })
    
            this.nextLucasMessage();
          }, (this.state.lucasMessageId === 0 || ! hasNext)?0:Math.max(500,Math.min(this.state.lucasMessagesList[this.state.lucasMessageId].length*100,3000)));
          
          this.nextMessageTimer = timer;
        }
      } else {
        this.reset();
      }

      setTimeout(() => {
        //scroll.scrollToBottom({containerId:"chat-container"});
      }, 200);
    } 
  }

  getRiddlesLeft() {
    let left = 5;
    const {map} = this.props;

    if(map.bellecourt)
      left--;
    if(map.or)
    left--; 
    if(map.canut)
    left--; 
    if(map.basilique)
    left--; 

    return left;
  }

  reset() {
    this.props.dispatch(tooglePlay(false));
    this.setState({
      lucasMessagesList : [],
      lucasMessageId : 0,
      playing:false,
      userAnswer : {
        content:null,
        format:'',
        showing:false,
      },
      typing: false
    });
  }

  userAnswered(value, validVal) {
    const isGood = (validVal && (validVal == value)) || !validVal;

    this.props.dispatch(this.addMessage(value, true));

    if(isGood) {
      this.setState({
        userAnswer:{
          content:null,
        },
        lucasMessageId: this.state.lucasMessageId+1,
      });
      this.props.dispatch(tooglePlay(true));
    } else {

      this.setState({
        userAnswer:{
          content:null,
        },
      });
  
      setTimeout(() => {
        this.addWrongAnswerMessage();
      }, 700);
      
      setTimeout(() => {
        this.props.dispatch(tooglePlay(true));
      }, 1000)
      
    }
  }

  addWrongAnswerMessage() {

    const errors = {
      "fr":[
        "Ça ne marche pas...",
        "Non, rien ne se passe.",
        "Hum, je crois qu'il faut essayer autre chose",
        "Essayons autre chose, d'accord ?",
        "Ça ne marche pas.",
        "Ce n'est pas ça.",
        "Ça ne fonctionne pas",
        "Ça ne fonctionne pas...",
      ],
      "en": [
        "Doesn't work",
        "No, it doesn't work",
        "No, sorry...",
        "Well, let's try again",
        "Any other idea?"
      ]
    }

    this.props.dispatch(this.addMessage(errors[this.state.ln][Math.floor(Math.random()*errors[this.state.ln].length)]));
  }

  renderContent() {
    const paddingBottomClass = (this.props.fl)?"padding-chat":"";
    return (
      <div id="chat-container" className={"chat-major-container "}>
        <Chat typing={this.state.typing} onCallBackNext={() => { this.callbackNext() }} />
        <div id={"fresh-air"}></div>
        <UserMessage content={this.state.userAnswer.content} callback={(val, valid) => this.userAnswered(val, valid)}/>
        <div id={"fresh-air-big"}></div>
        <div className={paddingBottomClass}> </div>
      </div>
    )
  }

  callbackNext() {
    if(this.state.typing) {
      clearTimeout(this.nextMessageTimer);
      this.nextMessageTimer = null;
      const nextMessage = this.state.lucasMessagesList[this.state.lucasMessageId];
      this.props.dispatch(this.addMessage(nextMessage, false));
      
      this.setState({
        lucasMessageId: this.state.lucasMessageId+1,
        typing:false,
      })

      setTimeout(() => {
        this.nextLucasMessage();
      },100);
    }
  }
  
  scrollToBottom = (timing = "smooth") => {
    this.messagesEnd.scrollIntoView({ behavior: timing });
  }
  
  render() {
    return <div>
      {this.renderContent()}
      <div style={{ float:"left", clear: "both" }}
             ref={(el) => { this.messagesEnd = el; }}>
        </div>
    </div>;
  }
}

export default translate("")(withMixpanel()(connect(state => ({ 
        messages : state.lucas.messages,
        discussion: state.lucas.discussion,
        play : state.fl.play,
        activeTab: state.system.activeTab,
        fl: state.fl.done,
        map: state.map,
    }),
    null
)(Lucas)));