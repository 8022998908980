import React from "react";
import {connect} from 'react-redux';
import InventoryDetails from './inventoryDetails';
import "../css/app.scss";
import { translate } from "react-i18next"
import Image from './Image';

class Inventory extends React.Component {

  state = {
    currentItem : null
  }

  selectItem(key, item) {
    console.log("select : "+item)
    this.setState({
      id: key,
      currentItem:item
    })
  }

  renderContent() {
    const { i18n } = this.props

    return ( 
      <div className={""} id="inventory-container">
        {
          this.props.items.map((element, key) => {
            const newClass = (element.isNew)?"new":"";
            return (
              <div key={"item_"+key} className={"item "+newClass} onClick={() => { this.selectItem(key, element) }}>
                {
                  /*<img src={require("../img/items/"+element.content[i18n.language].img)}/>*/
                  <Image alt="" filename={element.content[i18n.language].img} />
                }
                
              </div>
            )
          })
        }
        {
          (this.state.currentItem)?
          <InventoryDetails item={this.state.currentItem} id={this.state.id} onExit={() => {this.setState({currentItem:null})}} />:
          null
        }
      </div>
    )
  }

  render() {
    return <div>
      {this.renderContent()}
    </div>;
  }
}

export default translate()(connect(state => ({ 
        activeTab: state.system.activeTab,
        items: state.inventory.items
    }),
    null
)(Inventory));