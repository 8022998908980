import React from "react";
import { translate } from "react-i18next"
import "../css/app.scss";

class UserMessage extends React.Component {

    state = {
        showing : true,
        currentInputValue:"",
        answerSelected:null,
        validValue:null
    }

    componentDidMount() {
        setTimeout( () => {
            this.setState({
                showing:false,
            })
        },700);
    }

    handleChange(e) {
        let val = e.target.value;
        const inputType = this.props.content;

        if(val === "")
            this.setState({currentInputValue:val});


        if (inputType.includes("%%INPUT_N")) {
            if(isNaN(val))
            return;
            else {
                val = parseInt(val);

                if(val < 9999 && val >= 0)
                    this.setState({currentInputValue:val});
            }        
        }
        else if (inputType === "%%INPUT_CAR%%") {
            this.setState({currentInputValue:val});
        }
      }

    showInput(type) {
        const { t } = this.props    

        let inputType = "";
        if (type.includes("%%INPUT_N"))
            inputType = "number";
        else if (type === "%%INPUT_CAR%%")
            inputType = "";
        
        const isNotReadyClass = (this.state.currentInputValue)?"":"inactive";
        
        return (
            <div>
                <span className="title">{t("enter_answer")}</span>
                <input className={"answer-user "+inputType} type={inputType} value={this.state.currentInputValue} onChange={(e) => {this.handleChange(e)}}/>
                <div onClick={() => { 
                    if(this.state.currentInputValue) {
                        const inputType = this.props.content;
                        let validValue = null;
                        if (inputType.split("%%").length === 3) {
                            validValue = inputType.split("%%")[2];
                        }
                        this.props.callback(this.state.currentInputValue, validValue); 
                        this.setState({currentInputValue:""})
                    }
                }}  className={"btn "+isNotReadyClass}>
                    {t("answer")}
                </div>
            </div>
        )
    }

    showAnswer() {
        const { t } = this.props    
        const {content} = this.props;
        let texts = [content];
        let multipleStyle;

        if(content.includes("\n")) {
            texts = content.split("\n");
            multipleStyle = "multiple";
        }

        const isNotReadyClass = ((! multipleStyle) || this.state.answerSelected)?"":"inactive";

        return (
            <div>
                {(texts.length > 1)?<span className="title">{t("select_answer")}</span>:<span className="title">{t("select_answer_one")}</span>}
                {
                    texts.map((text, id) => {
                        const activeClass = (this.state.answerSelected === (id+1))?"active":"";
                        return (
                            <p className={"answer-user "+multipleStyle+" "+activeClass} key={id+1} onClick={() => {this.setState({answerSelected:id+1})}} > 
                                {text}
                            </p>
                        )
                    })
                }
                <div onClick={
                        () => {
                            if(! multipleStyle || this.state.answerSelected) {
                                let value = "";
                                if(! multipleStyle)
                                    value = this.props.content;
                                else {
                                    value = this.props.content.split("\n")[this.state.answerSelected-1];
                                }

                                this.props.callback(value);
                            }
                        }
                    }  
                    className={"btn "+isNotReadyClass}>
                    {(multipleStyle)?t("answer"):t("next")}
                </div>
            </div>
        )
    }

    render() {
        if(! this.props.content) 
            return null;

        const showClass = (this.state.showing)?"showing-answer":"";

        return (
        <div className={"answer-user-container "+showClass}>
            {
                (this.props.content.includes("%%INPUT"))?
                this.showInput(this.props.content):
                this.showAnswer()
            }
        </div>
        );
    }
}

export default translate("chat")(UserMessage)