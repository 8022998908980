import React from "react";
import { translate } from "react-i18next"
import { withMixpanel } from 'gatsby-plugin-mixpanel'
import { isDev } from '../../utils'
import "../css/app.scss";
import LanguageSwitcher from "./languageSwitcher";
import {connect} from 'react-redux';
import { start } from '../../state/actions/system';

class Welcome extends React.Component {
  state = {
    title : "title fadeInUpSmall animated slow",
    difficulty : "fadeInUpSmall animated delay-1s slow",
    btn : "fadeIn btn animated delay-2s slow",
    logo: "fadeIn  animated delay-3s slow",
    flags: "fadeIn animated delay-2s slow",
    showLnPopup:true,
    ln:"",
    popupClass:"",
  }

  start() {
    this.setState({
      title : "title fadeOut animated delay-1s",
      difficulty : "fadeOut animated delay-1s",
      btn : "fadeOut btn animated clicked",
      logo:"fadeOut animated delay-1s",
      flags:"fadeOut animated delay-1s",
    })

    this.props.dispatch(start());

    setTimeout(() => {
      this.props.onClose();
    }, 2000);
  }

  componentDidMount() {
    const { mixpanel,i18n } = this.props
    mixpanel.track((isDev()?"dev_":"")+'welcome');
    console.log("track welcome");

    if(!i18n.language)
      i18n.changeLanguage("en")
  }

  hidePopup() {
    if(this.state.ln) {
      this.setState({
        popupClass: "animated fadeOut"
      })

      setTimeout(() => {
        this.setState({showLnPopup:false})
      }, 700)
    }
  }

  getLnPopup(t) {
    const { i18n } = this.props
    const buttonIsActive = (this.state.ln)?"":"inactive";

    return (
    <div className={"popup "+this.state.popupClass}>
      <div className="container animated fadeInUpSmall">
        <h1>{t("select")}</h1>
        <LanguageSwitcher onSelect={(ln) => {this.setState({ln:ln})}}/>
        <div onClick={() => {this.hidePopup()}}  className={"btn "+buttonIsActive}>
        {t("continue")}
        </div>
      </div>
    </div>
    );
  }

  getContent(t) {
    return (
      <div>
        <p id="title" className={this.state.title}>
          <strong>MISSION<br/>{t("title")}</strong>
          {t("description1")}<br/><br/>
          {t("description2")}
        </p>

        <span id='info' className={this.state.difficulty}>
          <span><img src={require('../img/time.svg')}/>{t("duration")}</span>
          <span><img src={require('../img/difficulty.svg')}/>{t("difficulty")}</span>
        </span>

        <div onClick={() => {this.start()}}  className={this.state.btn}>
            {t("start")}
        </div>

        <img id="logo" src={require('../img/logo.png')} className={this.state.logo} />
      </div>
    )
  }

  render() {
    const { t } = this.props
    
    return <div id={"welcome-container"}>

      <div className={"welcome-content-container"}>
        {
          (this.state.showLnPopup)? 
          this.getLnPopup(t):
          this.getContent(t)
        }
      </div>
    </div>;
  }
}

export default translate("welcome")(withMixpanel()(connect(null,null)(Welcome)))