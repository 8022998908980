import React from "react";
import {connect} from 'react-redux';
import PinchZoomPan from "./PinchZoomPan/PinchZoomPan";
import Draggable, {DraggableCore} from 'react-draggable'; // Both at the same time
import PopupLucas from "./popupLucas";
import "../css/app.scss";
import {activeTab} from "../../state/actions/system"
import {toogleMap} from "../../state/actions/map"
import {loadDiscussion} from "../../state/actions/lucas"
import { toast, ToastContainer, Slide } from 'react-toastify';
import { translate } from "react-i18next"
import 'react-toastify/dist/ReactToastify.min.css';


class LyonMap extends React.Component {

  state = {
    dragging : false,
    showLoader: true,
    showLoaderAnimateOut: false,
    mapPosition: {
      x:0,
      y:0
    },
    dimensions: {
      width:0,
      height:0,
    },
    mapScale:0.5,
    lucas: null,
    emeteurs: {
      or:-1,
      basilique:-1,
      canut:-1,
      bellecourt:-1,
      hotel:-1,
    }
  }

  areas = {
    "or": {
      x1:75,
      x2:90,
      y1:0,
      y2:15,
    },
    "basilique": {
      x1:15,
      x2:38,
      y1:55,
      y2:75,
    },
    "bellecourt": {
      x1:35,
      x2:48,
      y1:75,
      y2:95,
    },
    "canut": {
      x1:40,
      x2:55,
      y1:0,
      y2:15,
    },
    "hotel": {
      x1:35,
      x2:52,
      y1:35,
      y2:48,
    },
  }

  clickTimer = null;

  constructor(props) {
    super(props);
  }

  onImageLoaded(img) {
    this.setState({
      dimensions:{
        height:img.height,
        width:img.width
      }
    });
  }

  handleStart(e, data) {
    this.setState({
      dragging: true,
      inertie: ""
    });
  };

  handleDrag(e, data) {
    let {x,y} = data;

    this.setState({
      mapPosition : {
        x:x,
        y:y
      },
    })
  };

  handleStop(e, data) {
    let {x,y} = data;

    if(x > window.innerWidth/2){
      x = window.innerWidth/2;
    }
    if(x < -this.state.dimensions.width + window.innerWidth/2){
      x = -this.state.dimensions.width + window.innerWidth/2;
    }
    if(y > window.innerHeight/2 ){
      y = window.innerHeight/2 ;
    }
    if(y < -this.state.dimensions.height + window.innerHeight/2) {
      y = -this.state.dimensions.height+ window.innerHeight/2;
    }
    

    this.setState({
      dragging: false,
      inertie: "inertie",
      mapPosition : {
        x:x,
        y:y
      },
    });

    setTimeout(() => {
      this.setState({
        inertie: ""
      })
    }, 700);
  };
  
  notify(message) {
    toast.info(message,{
      position: toast.POSITION.TOP_CENTER,
      className: "toast"
    });
  } 

  handleClickDown(scale, coordinate, fileinfo) {
    if(this.clickTimer){
      clearTimeout(this.clickTimer);
      this.clickTimer = null;
      return;
    }

    clearTimeout(this.clickTimer);

    this.clickTimer = setTimeout(() => {
      this.setState({
        dragging:false
      })
    

      if(! this.state.dragging){
        const originalwidth = fileinfo.imageDimensions.width;
        const originalHeight = fileinfo.imageDimensions.height;
        const viewWidth = originalwidth*scale;
        const viewHeight = originalHeight*scale;


        const x = (coordinate.x - this.state.mapPosition.x) * 100 / viewWidth; 
        const y = (coordinate.y - this.state.mapPosition.y) * 100 / viewHeight; 
        
        const isNotGoodValue = isNaN(x) || isNaN(y)

        if(! isNotGoodValue) {
          // TEST OR //
          if(! this.props.map.or && this.state.emeteurs.or === -1 && x > this.areas.or.x1 && x < this.areas.or.x2 && y > this.areas.or.y1 && y < this.areas.or.y2) {
            console.log("or");
            this.setState({
              emeteurs: {
                ...this.state.emeteurs,
                or:0
              }
            });

            setTimeout(() => {
              this.setState({
                lucas:"or",
              })
            }, 400);
          } else if (! this.props.map.basilique && this.state.emeteurs.basilique === -1 && x > this.areas.basilique.x1 && x < this.areas.basilique.x2 && y > this.areas.basilique.y1 && y < this.areas.basilique.y2) {
            // BASILIQUE
            console.log("basilique");
            this.setState({
              emeteurs: {
                ...this.state.emeteurs,
                basilique:0
              }
            });

            setTimeout(() => {
              this.setState({
                lucas:"basilique",
              })
            }, 400);
          } else if (! this.props.map.canut && this.state.emeteurs.canut === -1 && x > this.areas.canut.x1 && x < this.areas.canut.x2 && y > this.areas.canut.y1 && y < this.areas.canut.y2) {
            // CANUT
            console.log("canut");
            this.setState({
              emeteurs: {
                ...this.state.emeteurs,
                canut:0
              }
            });

            setTimeout(() => {
              this.setState({
                lucas:"canut",
              })
            }, 400);
          } else if (! this.props.map.bellecourt && this.state.emeteurs.bellecourt === -1 && x > this.areas.bellecourt.x1 && x < this.areas.bellecourt.x2 && y > this.areas.bellecourt.y1 && y < this.areas.bellecourt.y2) {
            // belcourt
            console.log("bellecourt");
            this.setState({
              emeteurs: {
                ...this.state.emeteurs,
                bellecourt:0
              }
            });

            setTimeout(() => {
              this.setState({
                lucas:"bellecourt",
              })
            }, 400);
          } else if (( false || (this.props.map.or && this.props.map.canut && this.props.map.basilique && this.props.map.bellecourt)) && this.state.emeteurs.hotel === -1 && x > this.areas.hotel.x1 && x < this.areas.hotel.x2 && y > this.areas.hotel.y1 && y < this.areas.hotel.y2) {
            // hotel
            console.log("hotel");
            this.setState({
              emeteurs: {
                ...this.state.emeteurs,
                hotel:0
              }
            }); 

            setTimeout(() => {
              this.setState({
                lucas:"hotel",
              })
            }, 400);
          } else {
            // this.notify(this.props.t("nothing"));
          }
        }
      }
      this.clickTimer = null;
    }, 300)

  }

  handlePan(left, top) {
    if(!isNaN(left) && !isNaN(top)) {
      this.setState({
        mapPosition: {
          x:left,
          y:top
        },
        dragging:true
      })
    }
    clearTimeout(this.clickTimer);
  }

  handleZoom(scale, left, top) {
    if(!isNaN(left) && !isNaN(top)) {
      this.setState({
        mapScale: scale,
        mapPosition: {
          x:left,
          y:top
        }
      })
    }
  }

  switchToLucas() {
    this.props.dispatch(activeTab(3));
    console.log("OPEN > "+this.state.lucas);
    setTimeout(() => {
      this.props.dispatch(loadDiscussion(this.state.lucas));
      this.setState({lucas:null});
      this.props.dispatch(toogleMap(false));
    }, 400)
  }

  componentDidMount() {
    this.setState({showLoader:true});

    setTimeout( () => {
      this.setState({showLoaderAnimateOut:true})
    }, 1000);

    setTimeout( () => {
      this.setState({showLoader:false, showLoaderAnimateOut:false})
    }, 2300);
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.activeTab !== prevProps.activeTab && this.props.activeTab === 1) {
      this.setState({showLoader:true});

      setTimeout( () => {
        this.setState({showLoaderAnimateOut:true})
      }, 300);

      setTimeout( () => {
        this.setState({showLoader:false, showLoaderAnimateOut:false})
      }, 1300);
    }
  }
  
  getProgression() {
    let progress = 0;
    if(this.props.map.or)
      progress++;
    if(this.props.map.canut)
      progress++;
    if(this.props.map.basilique)
      progress++;
    if(this.props.map.bellecourt)
      progress++;
    if(this.props.map.hotel)
      progress++;

    const total = 5;

    return (
      <div>
        <strong id='left'>{(total-progress)}</strong>
        <span>{((total-progress) > 1)?this.props.t("jammers"):this.props.t("jammer")}</span>
      </div>
    );
  }
  
  renderContent() {
    const maxOffLeft = (this.imgElement)?-this.imgElement.current.offsetWidth:0;

    return ( 
      <div className={""} id="map-container">
      {
        /*
        (this.props.activeTab === 1)?
        <Draggable 
            defaultPosition={{x: 0, y: 0}}
            position={{x:this.state.mapPosition.x,y:this.state.mapPosition.y}}
            onStart={(e, data) => {this.handleStart(e, data)}}
            onDrag={(e, data) => {this.handleDrag(e, data)}}
            onStop={(e, data) => {this.handleStop(e, data)}}
            onMouseDown={(e) => {this.handleClick(e) }}
            >
            <img src={require("../../assets/img/items/maps.jpg")} onLoad={this.onImgLoad} className={this.state.inertie}/>
        </Draggable>:null
        */
        <PinchZoomPan zoomButtons={false} doubleTapBehavior='zoom' position='center' initialScale={this.state.mapScale} minScale={0.3} maxScale={2} 
                      onClickDown={(scale, coordinate, fileinfo) => {this.handleClickDown(scale, coordinate, fileinfo)}}
                      onPan={(left, top) => { this.handlePan(left, top)}}
                      onZoom={(scale, left, top) => { this.handleZoom(scale, left, top)}}
                      onImageLoaded={(dimension) => { this.onImageLoaded(dimension)}}
                      onShow={(scale, left, top) => { this.handleZoom(scale, left, top)}}>
          <img src={require("../../assets/img/items/maps.jpg")} className={this.state.inertie}/>
        </PinchZoomPan>
      } 
        {
          (this.state.mapPosition && this.state.mapScale && (this.props.map.or))?
            <div className="dot blue has-ripple" style={ {
              left:this.state.mapPosition.x + (this.areas.or.x1+(this.areas.or.x2-this.areas.or.x1)/2)*(this.state.dimensions.width*this.state.mapScale)/100,
              top:this.state.mapPosition.y + (this.areas.or.y1+(this.areas.or.y2-this.areas.or.y1)/2)*(this.state.dimensions.height*this.state.mapScale)/100
            }}></div>
          :null
        }
        {
          (this.state.mapPosition && this.state.mapScale && (this.props.map.basilique))?
            <div className="dot blue has-ripple" style={ {
              left:this.state.mapPosition.x + (this.areas.basilique.x1+(this.areas.basilique.x2-this.areas.basilique.x1)/2)*(this.state.dimensions.width*this.state.mapScale)/100,
              top:this.state.mapPosition.y + (this.areas.basilique.y1+(this.areas.basilique.y2-this.areas.basilique.y1)/2)*(this.state.dimensions.height*this.state.mapScale)/100
            }}></div>
          :null
        }
        {
          (this.state.mapPosition && this.state.mapScale && (this.props.map.canut))?
            <div className="dot red has-ripple" style={ {
              left:this.state.mapPosition.x + (this.areas.canut.x1+(this.areas.canut.x2-this.areas.canut.x1)/2)*(this.state.dimensions.width*this.state.mapScale)/100,
              top:this.state.mapPosition.y + (this.areas.canut.y1+(this.areas.canut.y2-this.areas.canut.y1)/2)*(this.state.dimensions.height*this.state.mapScale)/100
            }}></div>
          :null
        }
        {
          (this.state.mapPosition && this.state.mapScale && (this.props.map.bellecourt))?
            <div className="dot red has-ripple" style={ {
              left:this.state.mapPosition.x + (this.areas.bellecourt.x1+(this.areas.bellecourt.x2-this.areas.bellecourt.x1)/2)*(this.state.dimensions.width*this.state.mapScale)/100,
              top:this.state.mapPosition.y + (this.areas.bellecourt.y1+(this.areas.bellecourt.y2-this.areas.bellecourt.y1)/2)*(this.state.dimensions.height*this.state.mapScale)/100
            }}></div>
          :null
        }
        <div className="counter">{this.getProgression()}</div>
        {
          (!this.props.map.active)?
            <div className="map-disabled">VOUS DEVEZ FINIR DE DÉSACTIVER LE BROUILLEUR</div>:
          null
        }
      </div>
    )
  }

  renderLucas() {
    if(! this.state.lucas)
      return;

    return (
      <PopupLucas onClose={() => { this.switchToLucas() } } />
    )
  }

  render() {
    return <div>
      {this.renderContent()}
      {this.renderLucas()}
      <ToastContainer autoClose={3000} transition={Slide} hideProgressBar />
      {(this.state.showLoader)?<div id='map-loader' className={(this.state.showLoaderAnimateOut)?"myfadeOut":""}></div>:null}
    </div>;
  }
}

export default translate()(connect(state => ({ 
        activeTab: state.system.activeTab,
        map:state.map,
    }),
    null
)(LyonMap));