
export const localizedStringValue = (localizedString, locale, fallbackLng) => {
  const localeShort = locale.split('-')[0];
  if (localizedString[localeShort]){
    return localizedString[localeShort];
  } else {
    return localizedString[fallbackLng]
  }
}

export const currentLocale = (locale) => {
  return locale.split('-')[0];
}

export const millisToMinutesAndSeconds = (millis) => {
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + " min " + (seconds < 10 ? '0' : '') + seconds+ " secondes";
}

export const isDev = () => {
  return false
}

export const plural = (val) => {
  return (val > 1)?"s":"";
}

export const asyncForEach = async (array, callback) => {
  if (array) {
    /* eslint-disable no-await-in-loop */
    /* eslint-disable callback-return */
    for (let index = 0; index < array.length; index += 1) {
      await callback(array[index], index, array);
    }
    /* eslint-enable no-await-in-loop */
    /* eslint-enable callback-return */
  }
}