import React from "react";
import {connect} from 'react-redux';
import { translate } from "react-i18next"
import Lottie from 'react-lottie';
import "../css/app.scss";
import textOR from 'raw-loader!../annecdotes/fr/or.txt';
import textBasilique from 'raw-loader!../annecdotes/fr/basilique.txt';
import textCanut from 'raw-loader!../annecdotes/fr/canut.txt';
import textBellecourt from 'raw-loader!../annecdotes/fr/bellecourt.txt';
import textHotel from 'raw-loader!../annecdotes/fr/hotel.txt';
import textOREN from 'raw-loader!../annecdotes/en/or.txt';
import textBasiliqueEN from 'raw-loader!../annecdotes/en/basilique.txt';
import textCanutEN from 'raw-loader!../annecdotes/en/canut.txt';
import textBellecourtEN from 'raw-loader!../annecdotes/en/bellecourt.txt';
import textHotelEN from 'raw-loader!../annecdotes/en/hotel.txt';

class Annecdote extends React.Component {

  state = {
    showing : true,
    content: ""
  }
  txtsBD = {
    "fr": {
      "or":textOR,
      "basilique":textBasilique,
      "canut":textCanut,
      "bellecourt":textBellecourt,
      "hotel":textHotel
    },
    "en": {
      "or":textOREN,
      "basilique":textBasiliqueEN,
      "canut":textCanutEN,
      "bellecourt":textBellecourtEN,
      "hotel":textHotelEN
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        showing:false
      })
    }, 100)

    this.loadText();
  }

  loadText() {
    const { i18n } = this.props
    let txt = this.txtsBD[i18n.language][this.props.content];

    this.setState({
      content:txt
    })
  }
  
  render() {
    const showingClass = (this.state.showing)?"showing":"";

    const defaultOptions = {
      loop: false,
      autoplay: true, 
      animationData: require('../img/book.json'),
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      },
    };

    return <div id="annecdote-details-container" onClick={() => { this.props.onExit() }} className={showingClass}>
      <div className="txt-content">
        <Lottie options={defaultOptions}
              height={100}
              resizeMode="cover"
              background="transparent"
              style={{marginBottom:'20px'}}/>
        <p>
        {
          this.state.content
        }
        </p>
        <img src={require('../img/close_aqua.svg')} className="back-top-right"/>
      </div>
    </div>;
  }
}

export default translate()(connect(null, null)(Annecdote));