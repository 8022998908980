import React from "react";
import {connect} from 'react-redux';

import TimeAgo from 'javascript-time-ago'
// Load locale-specific relative date/time formatting rules.
import en from 'javascript-time-ago/locale/en'
import fr from 'javascript-time-ago/locale/fr'
import "../css/app.scss";
import {openItem} from '../../state/actions/system';
import { translate } from "react-i18next"
import Image from './Image';

// Add locale-specific relative date/time formatting rules.
TimeAgo.addLocale(en);
TimeAgo.addLocale(fr);

class Message extends React.Component {

    state = {
        showing : true,
        timeAgo: null
    }

    componentWillMount() {
    }

    componentDidMount() {
        setTimeout( () => {
            this.setState({
                showing:false
            })
        },50);

        const { i18n } = this.props
        this.setState({
            timeAgo : new TimeAgo(i18n.language+'-'+i18n.language.toUpperCase())
        });
    }

    formatDate() {

    }
    
    render() {
        if(! this.props.message) 
            return null;

        const showClass = (this.state.showing)?"showing":"";

        if(this.props.isUserAnswer) {
            return (
                <p className={"message-lucas message-user "+showClass}> 
                    {this.props.message}
                    {(this.state.timeAgo) ? (<span className="time"> {this.state.timeAgo.format(Date.now() - (Date.now() - this.props.timestamp))}</span>) : null}
                </p>
            )
        }

        let message = this.props.message;

        if(message.includes("%%ADD_")) {
            const { i18n } = this.props
            const item = message.split('%%ADD_')[1];
            let keys = item.split("%");
            let itemName = keys[1];
            //message = (<img src={require("../../assets/img/items/"+itemName)} />)
            message = (<span onClick={() => { this.props.dispatch(openItem(itemName)) }}><Image alt="" filename={itemName} /></span>)
        }

        return<div>
            <p className={"message-lucas "+showClass} onClick={() => { this.props.onCallBackNext() }}> 
                {message}
                {(this.state.timeAgo) ? (<span className="time"> {this.state.timeAgo.format(Date.now() - (Date.now() - this.props.timestamp))}</span>) : null}
            </p>
        </div>;
    }
}


export default translate("chat")(connect(null,null)(Message));