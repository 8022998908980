import React from "react";
import {connect} from 'react-redux';
import { animateScroll as scroll } from "react-scroll";

import {tooglePlay} from '../../state/actions/fl';

import Message from './message';

import "../css/app.scss";


class Chat extends React.Component {

  state = {
    popupSeen : false,
    showLoading : true
  }
  

  componentDidUpdate(prevProps, prevState) {

    if(prevProps.typing != this.props.typing) {
      this.setState({
        showLoading:true
      })

      setTimeout( () => {
          this.setState({
            showLoading:false
          })
      },50);
    }
  }

  render() {
    const showLoading = (this.state.showLoading)?"showing":"";

    return <div className="chat-container" id="chat-container">
        {this.props.messages.map((messageValue) => {
            return (
              <Message 
                  message={messageValue.value} 
                  timestamp={messageValue.timestamp}
                  isUserAnswer={messageValue.isUserAnswer} 
                  key={messageValue.timestamp}
                  onCallBackNext={() => {this.props.onCallBackNext()}}/>
            )
        })}
        {(this.props.typing)?(
        <div id="message-loading-container" className={showLoading}>
          <img src={require("../../assets/img/message-loading.svg")} id="message-loading" onClick={() => {this.props.onCallBackNext()}}/>
        </div>):null}
    </div>;
  }
}

export default connect(state => ({ 
        messages : state.lucas.messages
    }),
    null
)(Chat);