import React from "react";
import {connect} from "react-redux";
import { translate } from "react-i18next"

import "../css/app.scss";


export class PopupLucas extends React.Component {

  state = {
    title : "fadeInUpSmall animated delay-1s",
    btn : "fadeIn btn animated delay-2s",
    img: "lucas fadeInDownSmall animated delay-1s",
    icon: "lucas fadeIn animated",
    lineLeft: "line fadeInLeft animated",
    lineRight: "line fadeInRight animated",
    bg : "popup-lucas fadeIn animated",
    flLayer: ""
  }

  click() {
    this.setState({
      title : "fadeOut animated delay-1s",
      btn : "fadeOut btn animated clicked",
      img: "lucas fadeOut animated delay-1s",
      lineLeft: "line fadeOut animated delay-1s",
      lineRight: "line fadeOut animated delay-1s",
      icon: "lucas fadeOut animated delay-1s",
      bg : "popup-lucas fadeOut animated delay-1s",
      flLayer: (!this.props.fl.done)?"visible":"",
    })

    setTimeout(() => {
      this.props.onClose();
    }, 1000);
  }
  

  render() {
    const { t } = this.props

    return <div className={"popup-lucas-container animated fadeIn"}>
        <div id='layer'></div>
        <div  className={this.state.bg}>
            <img src={require('../img/npc_lucas@4x.png')} className={this.state.img}/>
            <div id="icon-container">
              <span className={this.state.lineLeft}></span>
              <img src={require('../img/ic_mail_white@4x.png')} className={this.state.icon} id='icon-msg'/>
              <span className={this.state.lineRight}></span>
            </div>
            <span id="title"  className={this.state.title}>
              {t("new_message")}
            </span>
            <div onClick={() => this.click()} className={this.state.btn}>
              {t("open")}
            </div>
        </div>

        <div id="to-first-loop-layer" className={this.state.flLayer}></div>
    </div>;
  }
}


export default translate("chat")(connect(state => ({ 
  fl : state.fl,
}),
null
)(PopupLucas));